import React, { useEffect, useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => {
  const [isMount, setMount] = useState(false);
  useEffect(() => {
    setMount(true);
  }, [])

  if (!isMount) {
    return (
      <Layout>
      <SEO title="Loading" />
      <p>Loading...</p>
    </Layout>
    )
  }
  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>);
};

export default NotFoundPage;
